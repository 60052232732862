import React, { useContext, useEffect, useState } from 'react'
import CourseUniteBox from '../../Components/UserCourses/CourseUniteBox'
import axios from 'axios'
import config from '../../config'
import { Link, useParams } from 'react-router-dom';
import { auth } from '../../Context/AuthStore'
import Placeholder from '../../Components/Used/Placeholder'

export default function UserCoursePreview() {

  const [courseData, setCourseData] = useState(false);
  const [units, setUnits] = useState(false)
  const { course_id } = useParams()
  const { userAuth } = useContext(auth)
  const [courseExams, setCourseExams] = useState(false)

  const getCourseExams = () => {
    axios.get(`${config.basURL}exam/exam-course-list/${course_id}`, {
      headers: {
        'Authorization': config.ApiKey,
        'auth': `Bearer ${userAuth}`
      }
    }).then((res) => {
      setCourseExams(res.data);
    }).catch((err) => console.log(err))
  }

  const getCourseData = () => {
    axios.get(`${config.basURL}course/course-details/${course_id}`, {
      headers: {
        'Authorization': config.ApiKey
      }
    }).then((res) => {
      // console.log(res.data)
      setCourseData(res.data)
    }).catch((err) => console.log(err))
  }

  const getUnits = () => {
    axios.get(`${config.basURL}course/course-units/${course_id}`, {
      headers: {
        'Authorization': config.ApiKey
      }
    }).then((res) => {
      setUnits(res.data)
    }).catch((err) => console.log(err))
  }

  useEffect(() => {
    getCourseData()
    getUnits()
    getCourseExams()
  }, [])

  return <>
    <div className='user-course-preview-title'>
      <h3 className='fw-bold'>
        {courseData && courseData.name}
      </h3>
      <p>
        {courseData && courseData.about}
      </p>
    </div>
    <div className='user-course-preview-content container'>
      <div className='title mb-4'>
        <h6 className='py-3'>
          اختر الدرس الذي تريد أن تبدأ  به
          عنطريق اختيار (  الفيديو  او  الكويز  او  الواجب  ) من ثم اختر اي درس تريد من ثم البدء
        </h6>
      </div>
      <h5 className='py-3 mt-4'>
        فايلات
      </h5>
      <div className='course-files mb-5 py-3'>
        {
          courseData.course_file ? courseData.course_file.length > 0 ? courseData.course_file.map(file => <a href={`${file.file}`} className='file d-block' target='_blank'>
            <div className="icon">
              <i className="fa-solid fa-file"></i>
            </div>
            <p className='m-0'>
              {
                decodeURIComponent(file.file.split('/')[file.file.split('/').length - 1])
              }
            </p>
          </a>) : <p className='w-100 m-0 py-2 text-center'> لا يوجد فايلات في هذا الكورس </p> : <Placeholder />
        }
      </div>
      <h5 className='py-3 mt-4'>
        الوحدات
      </h5>
      <div className='row gap-5 mb-5'>
        {
          units && units.map((unit) => <CourseUniteBox data={unit} />)
        }
      </div>
      <div className="total-course-exams row gap-5 mb-5">
        {courseExams && courseExams.map(exam => {
          return <div className="col-lg-12" key={exam.id}>
            <h6 className="title w-100"> {exam.name} <span className='exam-duration'> <i className="fa-regular fa-clock"></i> {exam.exam_minutes}  دقائق </span> </h6>
            <p>{exam.description}</p>
            <Link to={`/quiz/${course_id}/${exam.id}`}>
              أبداء
            </Link>
          </div>
        })}
      </div>
    </div>
  </>
}
